import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import './App.css';

import icon6 from "./assets/images/services/icon6.png";
import pricing01 from './assets/images/services/pricing-01.png';
import img02 from './assets/images/about/img-02-.png';
import pricing03 from './assets/images/services/pricing-03.png';
import img5 from './assets/images/services/img5.png';
import icon4 from './assets/images/services/icon4.png';

import logo from './assets/images/akira.png';

import iconIcon1 from './assets/images/animate_icon/icon_1.png';
import iconIcon2 from './assets/images/animate_icon/icon_2.png';
import iconIcon3 from './assets/images/animate_icon/icon_3.png';
import iconIcon4 from './assets/images/animate_icon/icon_4.png';
import iconIcon5 from './assets/images/animate_icon/icon_5.png';
import iconIcon6 from './assets/images/animate_icon/icon_6.png';
import iconIcon7 from './assets/images/animate_icon/icon_7.png';

import icon19 from './assets/images/bg/19.png';
import img01 from './assets/images/about/img-01.png';

function App() {

  const homeRef = useRef(null);
  const akiraRef = useRef(null);
  const servicesRef = useRef(null);
  const contactsRef = useRef(null);

  const scrollToSection = (ref: any) => {
    if (ref && ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div>
      <header className="header default header-transparent">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <nav className="navbar navbar-static-top navbar-expand-lg header-sticky">
                <Link className="navbar-brand" to="" onClick={() => scrollToSection(homeRef)}><img className="img-fluid" style={{ width: 120, height: 50 }} src={logo} alt="logo" /></Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarmenu" aria-controls="navbarmenu" aria-expanded="false" aria-label="Toggle navigation">
                  <i className="fas fa-align-left" />
                </button>
                <div className="navbar-collapse collapse justify-content-end" id="navbarmenu">
                  <ul className="nav navbar-nav text-akira">
                    <li className="nav-item">
                      <Link to="" onClick={() => scrollToSection(homeRef)} className="nav-link">Página Inicial</Link>
                    </li>
                    <li className="nav-item">
                      <Link to="" onClick={() => scrollToSection(akiraRef)} className="nav-link lowecase">akira.ao</Link>
                    </li>
                    <li className="nav-item">
                      <Link to="" onClick={() => scrollToSection(servicesRef)} className="nav-link">Serviços</Link>
                    </li>
                    <li className="nav-item">
                      <Link to="" onClick={() => scrollToSection(contactsRef)} className="nav-link">Contactos</Link>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </header>

      <section className="banner position-relative" ref={homeRef} id="home">
        <div className="hero-app-1 custom-animation"><img src={iconIcon1} alt='' /></div>
        <div className="hero-app-2 custom-animation2"><img src={iconIcon2} alt='' /></div>
        <div className="hero-app-3 custom-animation3"><img src={iconIcon3} alt='' /></div>
        <div className="hero-app-4 custom-animation4"><img src={iconIcon4} alt='' /></div>
        <div className="hero-app-5 custom-animation5"><img src={iconIcon5} alt='' /></div>
        <div className="hero-app-6 custom-animation6"><img src={iconIcon6} alt='' /></div>
        <div className="hero-app-7 custom-animation7"><img src={iconIcon7} alt='' /></div>
        <div id="main-slider" className="swiper-container h-900 h-md-700 h-sm-auto ">
          <div className="swiper-wrapper auto">
            <div className="swiper-slide align-items-center d-flex  bg-primary-gradient py-5  py-sm-6 py-md-0">
              <div className="swipeinner container mt-0 mt-sm-5 mt-md-0">
                <div className="row align-items-center">
                  <div className=" col-xl-5 col-md-6 mt-4 mt-md-0">
                    <div className="contant">
                      <h6 className="text-primary" data-swiper-animation="fadeInDown" data-duration="1s" data-delay="0.5s">
                        Criação <span style={{ textTransform: "lowercase" }}>e</span> Inovação</h6>
                      <h1 className="text-dark mb-4" data-swiper-animation="fadeInUp" data-duration="1s" data-delay="1.0s">
                        Levando <span className='lowecase'>o</span> <span className='lowecase'>melhor da</span> tecnologia <span className='lowecase'>aos nossos</span> Clientes.</h1>
                      <div className="align-items-center">
                        <Link to="" className="btn btn-primary" data-swiper-animation="fadeInUp" data-duration="1s" data-delay="1.5s">»»<span /></Link>
                        <Link to="" onClick={() => scrollToSection(akiraRef)} className="btn btn-white lowecase" data-swiper-animation="fadeInUp" data-duration="1s" data-delay="2s">akira.ao</Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-7 col-md-6 mt-4 mt-md-0 z-index-9 text-end">
                    <img className="img-fluid banner-img" src={icon19} data-swiper-animation="fadeInRight" data-duration="1s" data-delay="0.1s" alt='' />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide align-items-center d-flex  bg-primary-gradient py-5  py-sm-6 py-md-0">
              <div className="swipeinner container mt-0 mt-sm-5 mt-md-0">
                <div className="row align-items-center">
                  <div className=" col-xl-5 col-md-6">
                    <div className="contant">
                      <h6 className="text-primary lowecase" data-swiper-animation="fadeInDown" data-duration="1s" data-delay="0.5s">assim mesmo ...</h6>
                      <h1 className="text-dark mb-4" data-swiper-animation="fadeInUp" data-duration="1s" data-delay="1.0s">Concretizando <span className='lowecase'>sonhos por meio da</span> tecnologia.</h1>
                      <div className="align-items-center">
                        <Link to="" className="btn btn-primary" data-swiper-animation="fadeInUp" data-duration="1s" data-delay="1.5s">««<span /></Link>
                        <Link to="" onClick={() => scrollToSection(akiraRef)} className="ms-4 lowecase" data-swiper-animation="fadeInUp" data-duration="1s" data-delay="2s">akira.ao</Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-7 col-md-6 mt-4 mt-md-0 z-index-9">
                    <img className="img-fluid banner-img" src={img01} data-swiper-animation="fadeInRight" data-duration="1s" data-delay="0.1s" alt='' />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="space-pb position-relative" ref={akiraRef} id="akira">
        <div className="position-absolute top-0 start-0 translate-middle">
          <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" height="1200px" width="1200px" viewBox="0 0 288 288">
            <linearGradient id="PSgrad_0" x1="70.711%" x2="0%" y1="70.711%" y2="0%">
              <stop offset="0%" stopColor="#f5f9ff" stopOpacity={1} />
              <stop offset="100%" stopColor="#f5f5f5" stopOpacity={1} />
            </linearGradient>
            <path fill="url(#PSgrad_0)">
              <animate repeatCount="indefinite" attributeName="d" dur="5s" values="M37.5,186c-12.1-10.5-11.8-32.3-7.2-46.7c4.8-15,13.1-17.8,30.1-36.7C91,68.8,83.5,56.7,103.4,45 c22.2-13.1,51.1-9.5,69.6-1.6c18.1,7.8,15.7,15.3,43.3,33.2c28.8,18.8,37.2,14.3,46.7,27.9c15.6,22.3,6.4,53.3,4.4,60.2 c-3.3,11.2-7.1,23.9-18.5,32c-16.3,11.5-29.5,0.7-48.6,11c-16.2,8.7-12.6,19.7-28.2,33.2c-22.7,19.7-63.8,25.7-79.9,9.7 c-15.2-15.1,0.3-41.7-16.6-54.9C63,186,49.7,196.7,37.5,186z; M51,171.3c-6.1-17.7-15.3-17.2-20.7-32c-8-21.9,0.7-54.6,20.7-67.1c19.5-12.3,32.8,5.5,67.7-3.4C145.2,62,145,49.9,173,43.4 c12-2.8,41.4-9.6,60.2,6.6c19,16.4,16.7,47.5,16,57.7c-1.7,22.8-10.3,25.5-9.4,46.4c1,22.5,11.2,25.8,9.1,42.6 c-2.2,17.6-16.3,37.5-33.5,40.8c-22,4.1-29.4-22.4-54.9-22.6c-31-0.2-40.8,39-68.3,35.7c-17.3-2-32.2-19.8-37.3-34.8 C48.9,198.6,57.8,191,51,171.3z; M37.5,186c-12.1-10.5-11.8-32.3-7.2-46.7c4.8-15,13.1-17.8,30.1-36.7C91,68.8,83.5,56.7,103.4,45 c22.2-13.1,51.1-9.5,69.6-1.6c18.1,7.8,15.7,15.3,43.3,33.2c28.8,18.8,37.2,14.3,46.7,27.9c15.6,22.3,6.4,53.3,4.4,60.2 c-3.3,11.2-7.1,23.9-18.5,32c-16.3,11.5-29.5,0.7-48.6,11c-16.2,8.7-12.6,19.7-28.2,33.2c-22.7,19.7-63.8,25.7-79.9,9.7 c-15.2-15.1,0.3-41.7-16.6-54.9C63,186,49.7,196.7,37.5,186z">
              </animate>
            </path>
          </svg>
        </div>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 mb-5 mb-lg-0 position-relative">
              <img className="img-fluid vert-move" src="assets/images/bg/10.png" alt='img10' />
            </div>
            <div className="col-lg-6 ps-lg-5">
              <div className="section-title">
                <h2 className="title lowecase">akira.ao</h2>
                <p>Somos uma startup angolana, vocacionada no desevolvimento de soluções tecnológicas. 
                  Nosso quadro de pessoal é maioritariamente jovem, com vontade de inovar, de crescer, de aprender e buscar sempre a melhor solução para oferecer aos nossos clientes.
                  De muitos porque escolher a akira como seu parceiro aqui apresentamos 4 deles:
                </p>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <ul className="list list-unstyled">
                    <li className="d-flex mb-4"><i className="fas fa-check text-primary" /><span><b>Experiência e Expertise:</b> A experiência acumulada de nossos profissionais ao longo de vários projectos permite que ofereçamos aos nossos clientes insights valiosos, melhores práticas e abordagens técnicas sofisticadas.</span></li>
                    <li className="d-flex mb-4"><i className="fas fa-check text-primary" /><span><b>Qualidade e Confiabilidade:</b> No processo de desevolvimento das soluções são aplicados rigorosos processos de controle de qualidade, testes e revisões para garantir que o resultado final atenda às expectativas do cliente e funcione conforme o esperado.</span></li>
                  </ul>
                </div>
                <div className="col-md-6">
                  <ul className="list list-unstyled">
                    <li className="d-flex mb-4"><i className="fas fa-check text-primary" /><span><b>Prazos e Cumprimento de Metas:</b> Nosso histórico na consistente entrega dentro dos prazos estabelecidos demonstram confiabilidade e comprometimento.</span></li>
                    <li className="d-flex mb-4"><i className="fas fa-check text-primary" /><span><b>Abordagem Personalizada e Comunicação Transparente:</b> Nosso processo de desenvolvimento das soluções adotam uma abordagem personalizada, adaptando-se às necessidades específicas de cada cliente. Além disso, manter uma comunicação transparente e constante ao longo do projecto permite que o cliente acompanhe o progresso do seu projecto e faça ajustes, sempre que necessário.</span></li>
                  </ul>
                </div>
              </div>
              <Link to="" onClick={() => scrollToSection(contactsRef)} className="btn btn-primary mt-3">Solicitar Serviço <i className="fa fa-angle-right ps-3" /></Link>
            </div>
          </div>
        </div>
      </section>

      <section className="space-ptb position-relative" ref={servicesRef} id="services">
        <div className="container">
          <div className="row justify-content-center position-relative z-index-9">
            <div className="col-lg-8 space-pb">
              <div className="section-title text-center">
                <h2 className="title text-white">Escolha <span className='lowecase'>o serviço que seu negócio precisa</span></h2>
                <p className="text-white">Tudo <span className="lowecase">que seu negócio precisa está aqui, um click apenas e mude o rumo de seu negócio.</span></p>
              </div>
            </div>
          </div>
        </div>
        <div className="position-absolute top-0 start-0 pricing-title-bg">
          <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width={3000} height={1188} preserveAspectRatio="xMidYMid" viewBox="0 0 3000 1188">
            <g transform="translate(1500,594) scale(1,1) translate(-1500,-594)">
              <linearGradient id="lg-0.0034396383733872327" x1={0} x2={1} y1={0} y2={0}>
                <stop stopColor="#023143" offset={0} />
                <stop stopColor="#023143" offset={1} />
              </linearGradient>
              <path fill="url(#lg-0.0034396383733872327)" opacity="0.7">
                <animate attributeName="d" dur="6.666666666666666s" repeatCount="indefinite" keyTimes="0;0.333;0.667;1" keySplines="0.2 0 0.2 1;0.2 0 0.2 1;0.2 0 0.2 1" begin="0s" values="M0 0L 0 1163.5595451206752Q 300 1092.6823103845466  600 1051.9201896332347T 1200 859.9971240569962T 1800 840.8659182269124T 2400 507.2328053620897T 3000 550.0263546076898L 3000 0 Z;M0 0L 0 1133.9440814277468Q 300 1008.6765292805919  600 964.8276431686902T 1200 906.6600541298845T 1800 657.4407463194185T 2400 516.5205384022386T 3000 568.0579418469126L 3000 0 Z;M0 0L 0 1119.1308390714116Q 300 1008.0968493943788  600 986.2850936130015T 1200 840.5795419134104T 1800 822.4871061292016T 2400 713.639466696095T 3000 518.5316739836966L 3000 0 Z;M0 0L 0 1163.5595451206752Q 300 1092.6823103845466  600 1051.9201896332347T 1200 859.9971240569962T 1800 840.8659182269124T 2400 507.2328053620897T 3000 550.0263546076898L 3000 0 Z">
                </animate>
              </path>
              <path fill="url(#lg-0.0034396383733872327)" opacity="0.7">
                <animate attributeName="d" dur="6.666666666666666s" repeatCount="indefinite" keyTimes="0;0.333;0.667;1" keySplines="0.2 0 0.2 1;0.2 0 0.2 1;0.2 0 0.2 1" begin="-3.333333333333333s" values="M0 0L 0 1100.6211475776472Q 300 1008.4832940035175  600 985.0477571420188T 1200 782.9613815947538T 1800 658.6763260668172T 2400 569.2097985299017T 3000 477.16861743549634L 3000 0 Z;M0 0L 0 1082.2274260911543Q 300 943.1843151451898  600 913.1578510607173T 1200 761.7322720876341T 1800 756.398548509769T 2400 631.5769000559573T 3000 403.3304327366001L 3000 0 Z;M0 0L 0 1172.211415696574Q 300 1106.2765989018353  600 1068.0261520869255T 1200 787.2364250110095T 1800 803.4099394963007T 2400 603.4903085562589T 3000 562.8350658963814L 3000 0 Z;M0 0L 0 1100.6211475776472Q 300 1008.4832940035175  600 985.0477571420188T 1200 782.9613815947538T 1800 658.6763260668172T 2400 569.2097985299017T 3000 477.16861743549634L 3000 0 Z">
                </animate>
              </path>
            </g>
          </svg>
        </div>
      </section>

      <section className="mt-lg-n8 mt-n7 position-relative z-index-9">
        <div className="container">
          <div className="row mb-4">
            <div className="col-md-4 pb-4 pb-md-0">
              <div className="pricing bg-white shadow-sm">
                <div className="pricing-plan">
                  <h3 className="pricing-title">Integração <span className="lowecase">de</span> Sistemas</h3>
                </div>
                <img className="img-fluid" src={icon6} style={{ width: 150 }} alt='' /><br />
                <Link to="" onClick={() => scrollToSection(contactsRef)} className="btn btn-primary btn-square mt-4">Solicitar Agora</Link>
              </div>
            </div>
            <div className="col-md-4 pb-4 pb-md-0">
              <div className="pricing bg-white shadow-sm">
                <div className="pricing-plan">
                  <h3 className="pricing-title">Desenvolvimento <span className="lowecase">de</span> Software</h3>
                </div>
                <img className="img-fluid" src={pricing01} alt='' /><br />
                <Link to="" onClick={() => scrollToSection(contactsRef)} className="btn btn-primary btn-square mt-4">Solicitar Agora</Link>
              </div>
            </div>
            <div className="col-md-4 pb-4 pb-md-0">
              <div className="pricing bg-white shadow-sm">
                <div className="pricing-plan">
                  <h3 className="pricing-title">Montagem <span className="lowecase">de</span> Relógio <span className="lowecase">de</span> Ponto</h3>
                </div>
                <img className="img-fluid" src={img02} style={{ width: 234 }} alt='' /><br />
                <Link to="" onClick={() => scrollToSection(contactsRef)} className="btn btn-primary btn-square mt-4">Solicitar Agora</Link>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 pb-md-0">
              <div className="pricing bg-white shadow-sm">
                <div className="pricing-plan">
                  <h3 className="pricing-title">Consultoria <br /><span className="lowecase">em</span> TI</h3>
                </div>
                <img className="img-fluid" src={pricing03} alt='' /><br />
                <Link to="" onClick={() => scrollToSection(contactsRef)} className="btn btn-primary btn-square mt-4">Solicitar Agora</Link>
              </div>
            </div>
            <div className="col-md-4 pb-4 pb-md-0">
              <div className="pricing bg-white shadow-sm">
                <div className="pricing-plan">
                  <h3 className="pricing-title">Instalação <span className="lowecase">e</span> Config. <span className="lowecase">de</span> GPS</h3>
                </div>
                <img className="img-fluid" src={img5} style={{ height: 150 }} alt='' /><br />
                <Link to="" onClick={() => scrollToSection(contactsRef)} className="btn btn-primary btn-square mt-4">Solicitar Agora</Link>
              </div>
            </div>
            <div className="col-md-4 pb-md-0">
              <div className="pricing bg-white shadow-sm">
                <div className="pricing-plan">
                  <h3 className="pricing-title">Reengenharia <span className="lowecase">de</span> Software</h3>
                </div>
                <img className="img-fluid" src={icon4} style={{ width: 150 }} alt='' /><br />
                <Link to="" onClick={() => scrollToSection(contactsRef)} className="btn btn-primary btn-square mt-4">Solicitar Agora</Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="space-ptb" ref={contactsRef} id="contacts">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-7 text-center text-center">
              <div className="mb-md-6 mb-5">
                <h2 className="font-weight-normal mb-2">Entraremos <span className='lowecase'>em contato!</span></h2>
                <p className="mb-0">Seria óptimo ajudar você! Se você tiver alguma necessidade, não hesite em nos enviar uma mensagem. Estamos ansiosos para ajudar você a transformar seu negócio! Respondemos dentro de 24 horas!
                </p>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row justify-content-start">
              <div className="col-md-2 col-sm-8">
                <div className="footer-image position-relative">
                  <img className="img-fluid p-4 pb-0 position-absolute bottom-0 end-0" src="assets/images/svg/marketing.svg" alt='' />
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-4">
              <div className="contact-address bg-light p-4 mb-5 mb-lg-0">
                <div className="d-flex mb-3">
                  <div className="ml-3">
                    <h6>Endereço</h6>
                    <p>Centralidade do Kilamba, Bloco M, Edifício M5, Apartamento 04/RC</p>
                  </div>
                </div>
                <div className="d-flex mb-3">
                  <div className="ml-3">
                    <h6>Email</h6>
                    <p>geral@akira.ao</p>
                  </div>
                </div>
                <div className="d-flex mb-3">
                  <div className="ml-3">
                    <h6>Telefone</h6>
                    <p>
                      (+244) 933 139 727 <br />
                      (+244) 998 960 728
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <form className="row mt-6">
                <div className="form-group col-md-6 mb-4">
                  <label className="mb-2">Nome</label>
                  <input type="text" className="form-control" placeholder="Nome" />
                </div>
                <div className="form-group col-md-6 mb-4">
                  <label className="mb-2">Telefone</label>
                  <input type="text" className="form-control" placeholder="Telefone" />
                </div>
                <div className="form-group col-md-6 mb-4">
                  <label className="mb-2">Email</label>
                  <input type="email" className="form-control" placeholder="Email" />
                </div>
                <div className="form-group col-md-6 mb-4">
                  <label className="mb-2">Empresa</label>
                  <input type="text" className="form-control" placeholder="Empresa" />
                </div>
                <div className="form-group col-md-12 mb-4">
                  <label className="mb-2">Mensagem</label>
                  <textarea className="form-control" rows={4} placeholder="Mensagem" defaultValue={""} />
                </div>
                <div className="col-md-12">
                  <button type="submit" className="btn btn-primary">Enviar Mensagem</button>
                </div>
              </form>

            </div>
          </div>
        </div>
      </section>
    </div >
  );
}

export default App;
